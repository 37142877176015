import type { CookieConsentConfig } from 'vanilla-cookieconsent';

const config: CookieConsentConfig = {
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {
      autoClear: {
        cookies: [{ name: /^_ga/ }, { name: '_gid' }],
      },
      services: {
        ga: {
          label: 'Google Analytics',
          onAccept: () => {},
          onReject: () => {},
        },
        another: {
          label: 'Another service',
          onAccept: () => {},
          onReject: () => {},
        },
      },
    },
    ads: {},
  },

  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom right',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'We use cookies',
          description:
            '🍪 This website uses cookies to improve your experience',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage Individual preferences',
          footer: `
            <a href="#path-to-impressum.html" target="_blank">Consent</a>
            <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
          `,
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Your Privacy Choices',
              description: `In this panel you can express some preferences...`,
            },
            {
              title: 'Strictly Necessary',
              description:
                'These cookies are essential for the proper functioning of the website and cannot be disabled.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Performance and Analytics',
              description: 'These cookies collect information about how you use our website...',
              linkedCategory: 'analytics',
              cookieTable: {
                caption: 'Cookie table',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Description',
                },
                body: [
                  { name: '_ga', domain: 'yourdomain.com', desc: 'Description 1' },
                  { name: '_gid', domain: 'yourdomain.com', desc: 'Description 2' },
                ],
              },
            },
            {
              title: 'Targeting and Advertising',
              description: 'These cookies are used to make advertising messages more relevant...',
              linkedCategory: 'ads',
            },
            {
              title: 'More information',
              description:
                'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
            },
          ],
        },
      },
    },
  },
};

export default config;
